// ----------------------------------------------------------------------

const accountData = JSON.parse(localStorage.getItem('profile'));
const accessToken = localStorage.getItem('accessToken');

const account = {
  displayName: accessToken ? accountData?.fullname : '',
  emailId: accessToken ? accountData?.email : '',
  photoURL: 'https://www.w3schools.com/howto/img_avatar.png',
};
// ----------------------------------------------------------------------
// const profile = JSON.parse(localStorage.getItem('profile'));
// const { fullname, email } = profile;
// const account = {
//   displayName: fullname,
//   emailId: email,
//   photoURL: '/static/mock-images/avatars/avatar_default.jpg',
// };

// export default account;

export default account;
