import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import { useSelector } from 'react-redux';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import AddUser from './components/AddUser';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import { EditUser } from './components/EditUser';
import Project from './pages/Projects';
import NewProject from './pages/NewProject';
import { ViewProject } from './pages/ViewProject';
import { CreateTask } from './pages/CreateTask';
import { ViewTask } from './pages/ViewTask';
import { SubTaskView } from './pages/SubTaskView';
import { CreateTaskFromTask } from './pages/CreateTaskFromTask';
import Profile from './pages/Profile';
import Admins from './pages/Admins';
import ViewAdmin from './pages/ViewAdmin';

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuth } = useSelector((state) => state.login);
  const accessToken = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: accessToken ? <DashboardLayout /> : <Navigate to="/" />,
      // element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'member', element: <User /> },
        { path: 'blog', element: <Blog /> },
        { path: 'project', element: <Project /> },
        { path: 'project/new', element: <NewProject /> },
        { path: 'project/:id', element: <ViewProject /> },
        { path: 'project/create/:id', element: <CreateTask /> },
        { path: 'project/task', element: <SubTaskView /> },
        { path: 'project/task/create', element: <CreateTaskFromTask /> },
        { path: 'project/task/view', element: <ViewTask /> },
        { path: 'profile', element: <Profile /> },
        { path: 'account', element: <Admins /> },
        { path: 'account/view/:id', element: <ViewAdmin /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard/app" /> },
        // { path: 'login', element: <Login /> },
        { path: '/', element: accessToken ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'adduser', element: <AddUser /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: 'edit', element: <EditUser /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
