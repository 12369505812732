import {
  Card,
  Grid,
  Stack,
  Typography,
  CardContent,
  TablePagination,
  CircularProgress,
  useMediaQuery,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Logo, { CardLogo } from '../components/Logo';
import Page from '../components/Page';

export const ListTask = ({ getProject }) => {
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [deleteCategory, setDeleteCategory] = useState('');
  const navigate = useNavigate();
  const [input, setInput] = useState(1);

  const size = 9;

  const { id } = useParams();
  const [options, setOptions] = useState({ page: 0, size, projectId: id });
  console.log('pro', id);
  const matches = useMediaQuery('(max-width:600px)');

  const getTask = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/categories/tasks`, {
        params: options,
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.records);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/task/delete/category`, {
        params: { projectId: id, deletePassword, category: deleteCategory },
      });
      toast.success(res.data.message);
      setOpenDelete(false);
      getProject();
      getTask(options);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDelete = (e, category) => {
    e.preventDefault();
    setDeleteCategory(category);
    setOpenDelete(true);
  };
  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(taskCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getTask(temp);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setOptions({ ...options, page: newPage });
    setIsLoading(true);
    getTask({ ...options, page: newPage });
  };
  useEffect(() => {
    getTask(options);
  }, []);

  const TaskColors = ['#ede8e8'];

  return (
    <Page>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {' '}
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Delete Category'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Typography variant="body1">{`Are you sure you want to delete ${deleteCategory} category?`}</Typography>
                <TextField
                  fullWidth
                  type="password"
                  label="Password"
                  name="deletePassword"
                  sx={{
                    minWidth: {
                      xs: '250px',
                      sm: '350px',
                    },
                    mt: 1,
                  }}
                  onChange={(e) => setDeletePassword(e.target.value)}
                  value={deletePassword}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={handleCloseDelete}>
                No
              </Button>
              <Button onClick={handleDelete} sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>{' '}
          <Stack direction="row" spacing={2} m={1}>
            <TextField
              size="small"
              style={{ maxWidth: '100px', maxHeight: '35px' }}
              type="number"
              component="div"
              label="Go to"
              value={input}
              onInput={(e) => setInput(e.target.value)}
            />
            <Button
              variant="contained"
              onClick={goToPage}
              sx={{ maxHeight: '35px', bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
            >
              Go
            </Button>
          </Stack>
          <Grid container padding={1} spacing={1} sx={12}>
            {task?.map((item, index) => (
              <Grid item key={index} sm={6} xs={12} md={4}>
                <Card
                  key={index}
                  style={{
                    background: TaskColors[index % 1],
                    paddingBottom: '10px',
                    height: '100%',
                    ...(!matches && { minHeight: '400px' }),
                  }}
                >
                  <CardContent
                    style={{
                      maxHeight: '400px',
                      overflowY: 'auto',
                    }}
                    className="scrollCard"
                  >
                    <Stack direction="row" justifyContent="space-between" alignItems={'start'}>
                      <Stack
                        direction="row"
                        spacing={1}
                        paddingBottom="15px"
                        alignItems={'center'}
                        onClick={() => navigate(`/dashboard/project/task?category=${item.category}&id=${id}`)}
                      >
                        <CardLogo />
                        <Typography
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          style={{ fontSize: '19px', fontWeight: 'bold', paddingLeft: '3px', paddingTop: '10px' }}
                        >
                          {item.category?.toUpperCase()}
                        </Typography>
                      </Stack>
                      <Delete
                        sx={{
                          cursor: 'pointer',
                        }}
                        onClick={(e) => handleOpenDelete(e, item.category)}
                      />
                    </Stack>
                    <Link
                      to={`/dashboard/project/task?category=${item.category}&id=${id}`}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <Grid container sx={12} direction="row" justifyContent="space-between">
                        {item.task.map((name, id) => (
                          <Grid item key={id} sm={10} xs={10} md={10} style={{ paddingTop: '15px' }}>
                            <Typography style={{ fontSize: '15px', fontWeight: 'bold', paddingLeft: '3px' }}>
                              {name.name}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={taskCount}
        page={pages}
        onPageChange={handlePageChange}
        rowsPerPage={size}
        showFirstButton
        showLastButton
      />
    </Page>
  );
};
