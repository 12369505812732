import { useState, useEffect } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
// material
import {
  Container,
  Stack,
  Typography,
  CircularProgress,
  Card,
  Button,
  Grid,
  Box,
  Zoom,
  Pagination,
  CardContent,
  AvatarGroup,
  Avatar,
  Divider,
  Tabs,
  Chip,
  Tab,
  TablePagination,
  Tooltip,
  Badge,
  TextField,
} from '@mui/material';
import { Icon } from '@iconify/react';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import CategoryIcon from '@mui/icons-material/Category';
import MessageIcon from '@mui/icons-material/Message';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearIcon from '@mui/icons-material/Clear';
import Filter from '../components/Filter';

// components
import Page from '../components/Page';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/products';
// import { sizeWidth } from '@mui/system';

// ----------------------------------------------------------------------

export default function Project() {
  const size = 12;
  const [searchParams, setSearchParams] = useSearchParams();
  const category = searchParams.get('category');
  const [preSelectedCategory, setPreSelectedCategory] = useState(category || '');
  const userId = searchParams.get('userId');
  const [projects, setProjects] = useState([]);
  const [isloading, setIsLoading] = useState(true);
  const [pages, setPages] = useState(0);
  const [filters, setFilters] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [options, setOptions] = useState({ size, page: 0, category, userId });
  const [input, setInput] = useState(1);
  const navigate = useNavigate();
  let filterOptions;
  const handleNextPage = () => {
    navigate('/dashboard/project/new', {
      state: {
        category: preSelectedCategory || null,
      },
    });
  };

  const getProject = (options) => {
    setIsLoading(true);
    console.log(options);
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.message === 'Projects successfully retrieved') {
          setIsLoading(false);
          setProjects(res.data.data.data || []);
          setProjectCount(res.data.data.maxRecord || 0);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    const temp = { ...options, page: newPage };
    setOptions(temp);
    setIsLoading(true);
    getProject(temp);
  };

  const clearFilters = () => {
    setFilters([]);
    setOptions({ size, page: 0 });
    getProject({ size, page: 0 });
  };

  const handleDelete = (data) => {
    const selected = data[0];
    setFilters(filters.filter((e) => e !== data));
    setOptions((current) => {
      const copy = { ...current };
      delete copy[selected];
      return copy;
    });
    getProject(options);
  };

  const applyFilters = (filter) => {
    const temp = { page: 0, size };

    if (filter.name.trim()) {
      temp.name = filter.name.trim();
    }
    if (filter.location.trim()) {
      temp.location = filter.location.trim();
    }
    if (filter.category.trim()) {
      temp.category = filter.category.trim();
      setPreSelectedCategory(filter.category);
    }

    if (filter.startDate.length) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate.length) {
      temp.endDate = filter.endDate;
    }
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }

    setOptions(temp);
    let filterEntries = Object.entries(filter);
    filterEntries = filterEntries.filter((item) => {
      return item[1] !== '';
    });
    setFilters(filterEntries);
    console.log(filterEntries);
    setPages(0);
    getProject(temp);
    console.log(filters);
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(projectCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getProject(temp);
  };

  useEffect(() => {
    getProject(options);
  }, [options]);
  useEffect(() => {
    if (category && !userId) {
      setFilters([['category', category]]);
    }
    if (userId && !category) {
      setFilters([['userId', userId]]);
    }
    if (category && userId) {
      setFilters([
        ['category', category],
        ['userId', userId],
      ]);
    }
  }, [category, userId]);

  const projectColors = ['#f0f2f7', '#f0f2f7', '#f0f2f7', '#f0f2f7'];

  return (
    <Page title="Dashboard: Projects">
      {isloading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Projects</Typography>
            <Filter options={options} applyFilters={applyFilters} />
          </Stack>
          <Card>
            <Stack
              direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'row', xs: 'column-reverse' }}
              marginTop={1}
              paddingX={2}
              paddingY={1}
              spacing={1}
              justifyContent="space-between"
            >
              {' '}
              <Stack direction="row" spacing={2} padding={0} mb={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={goToPage}
                  sx={{ maxHeight: '35px', bgcolor: '#136586', ':hover': { background: '#105470' }, color: 'white' }}
                >
                  Go
                </Button>
              </Stack>
              <Grid direction="row" spacing={1} flex="wrap" paddingTop={1}>
                {filters.map((item, key) => (
                  <Chip key={key} label={`${item[0]} : ${item[1]}`} onDelete={() => handleDelete(item)} />
                ))}
                {!!filters.length && (
                  <Button size="small" onClick={clearFilters} startIcon={<ClearIcon />}>
                    Clear Filters
                  </Button>
                )}
              </Grid>{' '}
              <Stack maxHeight="40px">
                <Button
                  variant="contained"
                  startIcon={<AddCircleOutlineSharpIcon />}
                  sx={{
                    maxHeight: '40px',
                    maxWidth: '120px',
                    bgcolor: '#136586',
                    ':hover': { background: '#105470' },
                    color: 'white',
                  }}
                  display="flex"
                  justifyContent={'space-between'}
                  onClick={handleNextPage}
                >
                  Project
                </Button>
              </Stack>
            </Stack>

            <Grid
              sx={{
                paddingLeft: '10px',
                paddingRight: '1px',
                '@media screen and (max-width: 450px)': {
                  paddingLeft: '6px',
                  paddingRight: '0px',
                },
              }}
              alignItems="center"
              container
              spacing={1}
              xs={12}
            >
              {projects?.map((item, id) => {
                return (
                  <Grid item sm={6} xs={12} md={4}>
                    <Link to={`/dashboard/project/${item._id}`} style={{ textDecoration: 'none' }}>
                      <Card
                        key={id}
                        // style={{ minHeight: '500px', height: '500px', backgroundColor: projectColors[id % 4] }}
                        style={{ backgroundColor: projectColors[id % 4] }}
                      >
                        <Box
                          sx={{
                            paddingX: '15px',
                            paddingY: '15px',
                            '@media screen and (max-width: 450px)': {
                              paddingX: '10px',
                            },
                          }}
                        >
                          <Grid xs={12} container direction="row" rowSpacing={3}>
                            <Grid item xs={12}>
                              <Stack direction={'row'} justifyContent="space-between">
                                <Typography
                                  variant="h6"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  color="#02071f"
                                >
                                  {item.name.toUpperCase()}
                                </Typography>
                                <ModeEditOutlinedIcon />
                              </Stack>
                            </Grid>

                            <Grid item xs={12}>
                              <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                                <AvatarGroup max={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  {item.memberData.map((mdata, id) => {
                                    return (
                                      <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                        <Badge
                                          invisible={!mdata.isBlocked}
                                          overlap="circular"
                                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          badgeContent={
                                            <Icon fontSize="15px" icon="material-symbols:block" color="red" />
                                          }
                                        >
                                          {!mdata?.profilePic ? (
                                            <Avatar alt="" key={id} sx={{ bgcolor: '#3b93b8' }}>
                                              {mdata.fullname.charAt(0).toUpperCase()}
                                            </Avatar>
                                          ) : (
                                            <Avatar
                                              alt=""
                                              key={id}
                                              sx={{ bgcolor: '#3b93b8' }}
                                              src={mdata.profilePic}
                                            />
                                          )}
                                        </Badge>
                                      </Tooltip>
                                    );
                                  })}
                                </AvatarGroup>
                                <Tooltip TransitionComponent={Zoom} title={item.managerData.email}>
                                  {item.managerData?.profilePic ? (
                                    <Avatar alt="" sx={{ bgcolor: '#3b93b8' }} src={item.managerData.profilePic} />
                                  ) : (
                                    <Avatar alt="" sx={{ bgcolor: '#3b93b8' }}>
                                      {item.managerData.fullname.charAt(0).toUpperCase()}
                                    </Avatar>
                                  )}
                                </Tooltip>
                              </Stack>
                            </Grid>
                            {/* <Grid item xs={12}>
                              <Grid container xs={12} rowGap={2}>
                                <Grid item xs={6} sm={6}>
                                  <Stack direction="column">
                                    <Stack direction="row">
                                      <PeopleSharpIcon />
                                      <Typography variant="h6">Manager</Typography>
                                    </Stack>
                                    <Stack paddingLeft={1} direction="row">
                                      <Tooltip TransitionComponent={Zoom} title={item.managerData.email}>
                                        <Typography variant="h7">{item.managerData.fullname.toUpperCase()}</Typography>
                                      </Tooltip>{' '}
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Stack direction="row" paddingLeft={{ md: 2, lg: 4 }}>
                                    <AttachFileIcon />
                                    <Typography variant="h6">{item.attachmentsCount} Files</Typography>
                                  </Stack>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                  <Stack direction="column">
                                    <Stack direction="row">
                                      <CategoryIcon />
                                      <Typography variant="h6">Category</Typography>
                                    </Stack>
                                    <Stack direction="row" paddingLeft={1}>
                                      <Tooltip
                                        TransitionComponent={Zoom}
                                        title={item.category.split('_').join(' ').toUpperCase()}
                                      >
                                        <Typography
                                          variant="h7"
                                          width="150px"
                                          whiteSpace="nowrap"
                                          overflow="hidden"
                                          textOverflow="ellipsis"
                                        >
                                          {item.category.split('_').join(' ').toUpperCase()}
                                        </Typography>
                                      </Tooltip>
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Stack direction="column" paddingLeft={{ md: 2, lg: 4 }}>
                                    <Stack direction="row">
                                      <LocationOnIcon />
                                      <Typography variant="h6">Location</Typography>
                                    </Stack>
                                    <Stack direction="row" paddingLeft={1}>
                                      <Stack direction="row" height="50px" overflow="hidden">
                                        <Tooltip TransitionComponent={Zoom} title={item.location}>
                                          <Typography variant="h7">{item.location}</Typography>
                                        </Tooltip>
                                      </Stack>
                                    </Stack>
                                  </Stack>
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                  <Stack direction="column">
                                    <Stack direction="row">
                                      <FlagCircleRoundedIcon />
                                      <Typography variant="h6">Start date</Typography>
                                    </Stack>
                                    <Stack direction="row" paddingLeft={1}>
                                      <Typography variant="h7">
                                        {Moment(item.startDate).format('DD/MMM/YYYY')}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                  <Stack direction="column" paddingLeft={{ md: 2, lg: 4 }}>
                                    <Stack direction="row">
                                      <AssignmentTurnedInIcon />
                                      <Typography variant="h6">End Date</Typography>
                                    </Stack>
                                    <Stack direction="row" paddingLeft={1}>
                                      <Typography>{Moment(item.endDate).format('DD/MMM/YYYY')}</Typography>{' '}
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Grid>



                              <Grid container xs={12}>
                                <Stack width="100%" height="50px" direction="column" justifyContent="flex-end">
                                  <Divider sx={{ paddingTop: '20px' }} />
                                  <Typography textAlign="center" paddingTop="20px" variant="h6">
                                    View More
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid> */}
                          </Grid>
                        </Box>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>

            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={projectCount}
              page={pages}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              showFirstButton
              showLastButton
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
