import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
import React from 'react';
import { EditUserForm } from './EditUserForm';
import useResponsive from '../hooks/useResponsive';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '0px',
}));

export const EditUser = ({ item, closePopup,getUsers }) => {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Container>
      <ContentStyle>
        <Typography variant="h4" gutterBottom>
          Edit User
        </Typography>
        <EditUserForm getUsers={getUsers}item={item} closePopup={closePopup} />
      </ContentStyle>
    </Container>
  );
};
