import io from 'socket.io-client';
import { useState, useEffect, useRef, useReducer } from 'react';
import './style.css';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import moment from 'moment';
import {
  Stack,
  TextField,
  Typography,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  Tooltip,
  IconButton,
  Zoom,
  CircularProgress,
  useMediaQuery,
  DialogContent,
  Fab,
  Badge
} from '@mui/material';
import { debounce } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfiniteScroll from 'react-infinite-scroll-component';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import SendIcon from '@mui/icons-material/Send';
import Iconify from '../Iconify';
import { chatReducer, INITIAL_STATE } from './ChatReducer';
import Message from './Message';
import { ACTION_TYPES } from './ChatActionTypes';

const socket = io(`${process.env.REACT_APP_API_URL}`);

function Chat({ handleChatClose, taskId, open3 }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')).id);
  const [limit, setLimit] = useState(10);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [message, setMessage] = useState('');
  const matches = useMediaQuery('(max-width:768px)');
  const [hasmore, setHasmore] = useState(true);
  const [scrollMore, setScrollMore] = useState(true);
  const inverse = true;
  const scrollRef = useRef();
  const messageRef = useRef();

  const [state, dispatch] = useReducer(chatReducer, INITIAL_STATE);

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const fabStyle = {
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'absolute',
    top: '88%',
    zIndex: '999',
    maxWidth: '200px',
  };
  const getMessages = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_FIRST_START });
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/admin/get`, {
        params: { taskId, limit },
      });
      console.log('messages', res.data);

      const length = res.data.data.chat.length;
      if (!length) {
        setScrollMore(false);
      }
      const chat = res.data.data.chat;
      dispatch({ type: ACTION_TYPES.FETCH_FIRST_SUCCESS, payload: res.data.data.chat });
      for (let i = 0; i < length; i += 1) {
        if (chat[i].isViewed) {
          const element = document.getElementById(i - 1 < 0 ? `id_${chat[i]._id}` : `id_${chat[i - 1]._id}`);
          element?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'end' });
          setUnreadMessages(i);
          break;
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPES.FETCH_FIRST_ERROR });
    }
  };

  const getMoreMessages = async () => {
    dispatch({ type: ACTION_TYPES.FETCH_MORE_START });
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/admin/get`, {
        params: { taskId, skipId: state.printMessages.slice(-1)[0]._id, limit },
      });
      const response = res.data.data.chat;
      const length = response.length;
      const moreMessages = [];
      for (let i = 0; i < length; i += 1) {
        if (response[i]._id === state.printMessages?.slice(-1)[0]._id) {
          moreMessages.push(response.slice(i + 1));
          break;
        }
      }
      dispatch({ type: ACTION_TYPES.FETCH_MORE_SUCCESS, payload: moreMessages[0] });
      if (!moreMessages[0].length) {
        setScrollMore(false);
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ACTION_TYPES.FETCH_MORE_ERROR });
    }
  };

  const getNewMessages = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/admin/get`, {
        params: { taskId, endId: state.printMessages[0]?._id },
      });
      const response = res.data.data.chat;
      let messages = [...state.printMessages];
      const newMessages = [];
      const length = response.length;
      if (messages.length) {
        for (let i = length - 1; i >= 0; i -= 1) {
          if (response[i]._id === messages[0]?._id) {
            newMessages.push(...response.slice(0, i));
            break;
          }
        }
        messages.unshift(...newMessages);
      } else {
        messages = response;
      }
      dispatch({ type: ACTION_TYPES.FETCH_NEW_SUCCESS, payload: messages });
      if (messages[0]?.adminData?._id === user) {
        const element = document.getElementById(`id_${messages[0]?._id}`);
        element?.scrollIntoView({ behavior: 'auto', block: 'end', inline: 'end' });
      }
      getUnreadCount();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendMessage = async (e) => {
    const temp = message;
    setMessage("");
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/chat/admin/add`, { taskId, message: temp });
      socket.emit("hi");
    } catch (error) {
      console.log(error);
    }
  };

  const handleScrollToNew = () => {
    dispatch({ type: ACTION_TYPES.VIEW_NEW_MESSAGES });
  };

  const handleReload = () => {
    getMessages();
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      handleSendMessage();
    }
  };

  useEffect(() => {
    socket.on('TASK_MESSAGE', (data) => {
      if (data.taskId === taskId) {
        getNewMessages();
      }
    });
    return () => {
      socket.off('TASK_MESSAGE');
      // socket.disconnect();
    };
  }, [state.printMessages]);

  useEffect(() => {
    socket.connect();
    getMessages();
  }, []);

  const updateView = async (target) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/chat/admin/view`, {
        taskId,
        chatId: target.replace('id_', ''),
      });
      getUnreadCount();
    } catch (error) {
      console.log(error);
    }
  };

  const getUnreadCount = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/chat/admin/unread/task`, {
        params: { taskId },
      });
      console.log(res);
      setUnreadMessages(res.data.data.unreadCount);
    } catch (error) {
      console.log(error);
    }
  };

  const myScrollHandler = debounce(() => {
    const unread = document.querySelectorAll('.unread');
    const dialogActions = document.querySelector('.dialogActions');
    const dialogActionsTop = dialogActions?.getBoundingClientRect().top;
    unread.forEach((item) => {
      const bottom = item?.getBoundingClientRect().bottom || 0;
      if (bottom < dialogActionsTop && bottom <= window.innerHeight) {
        console.log(bottom, item.id);
        updateView(item.id).then(() => {
          item.classList.replace('unread', 'read');
        });
      }
    });
  }, 1000);

  useEffect(() => {
    myScrollHandler();
  }, [state.printMessages]);

  useEffect(() => {
    window.addEventListener('scroll', myScrollHandler);
    return () => window.removeEventListener('scroll', myScrollHandler);
  }, [state.printMessages]);

  return (
    <Dialog fullWidth fullScreen={matches} open={open3}>
      <DialogTitle
        sx={{
          bgcolor: "#475985",
          color: 'white',
          maxHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          boxShadow: " rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
        }}
      >
        <Stack direction={'row'} justifyContent="space-between" marginX={-1} alignItems={'center'}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h6">CHAT  </Typography>
            {!!unreadMessages && (<Stack direction={"row"} size="small" sx={{
              borderRadius: "50%", backgroundColor: "white", color: "black", fontWeight: "400", fontSize: "10px",
              display: "flex", justifyContent: "center", alignItems: "center", padding: "1px", minHeight: "18px", minWidth: "18px"
            }}>
              {unreadMessages}
            </Stack>)}
          </Stack>
          <Stack direction="row">
            <Tooltip TransitionComponent={Zoom} title="Refresh Chat">
              <IconButton sx={{ color: 'white' }} onClick={handleReload}>
                <Iconify icon="ion:reload-circle-sharp" />
              </IconButton>
            </Tooltip>
            <Tooltip TransitionComponent={Zoom} title="Close">
              <IconButton onClick={handleChatClose}>
                <Iconify icon="ci:off-close" sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 0.5, overflow: 'hidden' }}>
        {state.loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            <Stack
              className="scrollhost"
              id="scrollableDiv"
              spacing={2}
              padding={1}
              style={{
                ...(matches ? { height: '80vh' } : { height: '76vh' }),
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
              onScroll={myScrollHandler}
            >
              <InfiniteScroll
                dataLength={state.printMessages?.length}
                next={getMoreMessages}
                style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column-reverse' }}
                inverse={inverse}
                hasMore={hasmore}
                loader={scrollMore && <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <CircularProgress />
                </div>}
                scrollableTarget="scrollableDiv"
              >
                {state.printMessages?.map((item, key) => (
                  <div key={key} id={`id_${item._id}`} className={item.isViewed ? 'read' : 'unread'}>
                    <Message item={item} />
                  </div>
                ))}
              </InfiniteScroll>
            </Stack>
            {/* {!!state.newMessages && (
              <Fab onClick={handleScrollToNew} variant="extended" size="medium" style={fabStyle}>
                {state.newMessages} new messages
              </Fab>
            )} */}
          </div>
        )}
      </DialogContent>
      <DialogActions sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} className="dialogActions">
        {/* {!!unreadMessages && (<Badge overlap="circular" badgeContent={unreadMessages} max={99} color="success" sx={{ ...(unreadMessages && { marginRight: "10px" }) }}>
          <ChatBubbleIcon sx={{ fontSize: "42px", color: "#6555E7" }} />
        </Badge>)} */}
        <TextField
          fullWidth
          placeholder="Write message"
          value={message}
          onKeyDown={(e) => handleKeypress(e)}
          onInput={(e) => setMessage(e.target.value)}
        />
        <IconButton variant="contained" onClick={handleSendMessage}>
          <SendIcon sx={{ color: "#475985", fontSize: '40px' }} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

export default Chat;
