import {
  Container,
  Card,
  Typography,
  Stack,
  TextField,
  FormControl,
  Button,
  Box,
  Autocomplete,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  CardContent,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import Page from '../components/Page';

export const CreateTask = () => {
  const [members, setMembers] = useState([]);
  const [projectCategory, setProjectCategory] = useState();
  const [subTasks, setSubTasks] = useState();
  const [catClicked, setCatClicked] = useState(true);

  const getMembers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/members`, {
        params: { projectId: id },
      })
      .then((res) => {
        console.log('membersss', res.data.data.members);
        setMembers(
          res.data.data.members.filter((item) => {
            return item.isBlocked === false;
          })
        );
      });
  };

  const getAllList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/task/list/all`, {}).then((res) => {
      console.log('getAllList', res);
      setProjectCategory(res.data.data.tasks);
      // setSubTasks(res.data.data.tasks?.map((sc, i) => sc));
      console.log('getdsf', res.data.data.tasks[0]?.subTask[0]);
    });
  };
  // const subTasks = () => {
  //   projectCategory.subTask.map((pc) => pc);
  // };

  const navigate = useNavigate();
  const { id } = useParams();
  console.log('projext', id);

  useEffect(() => {
    getMembers();
    getAllList();
  }, []);

  const TaskSchema = Yup.object().shape({
    name: Yup.string().required('Name required'),
    category: Yup.string().required('Category required'),
    startDate: Yup.date(),
    endDate: Yup.date(),
  });
  const formik = useFormik({
    initialValues: {
      projectId: id,
      name: '',
      category: '',
      description: '',
      startDate: '',
      endDate: '',
      members: [],
    },
    validationSchema: TaskSchema,
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/task`, values)
          .then((res) => {
            toast.success(res.data.message);
            navigate(-1);
          })
          .catch((err) => {
            toast.error('something went wrong');
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const handleMenuClick = (pc) => {
    setCatClicked(false);
    console.log(pc);
    setSubTasks(
      pc.subTask.map((subTask, i) => {
        return (
          <MenuItem key={i} value={subTask.name}>
            {subTask.name}
          </MenuItem>
        );
      })
    );
  };

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  return (
    <Page title="Project : Add task">
      <Container>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
            <Typography variant="h4" marginTop={2} marginLeft={2}>
              Create Task
            </Typography>
            <CloseIcon onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} />
          </div>
          <CardContent>
            <FormikProvider value={formik}>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} xs={12}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Typography variant="h6">Category</Typography> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="category"
                          label="Select Category"
                          {...getFieldProps('category')}
                          onChange={formik.handleChange}
                        >
                          {projectCategory?.map((pc, i) => {
                            return (
                              <MenuItem key={i} value={pc.name} onClick={() => handleMenuClick(pc)}>
                                {pc.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Typography variant="h6">Name</Typography> */}
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {catClicked ? 'Choose a Category' : 'Select Name'}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="name"
                          label="Select name"
                          sx={{ width: '100%', height: '55px' }}
                          {...getFieldProps('name')}
                          onChange={formik.handleChange}
                          disabled={catClicked}
                        >
                          {subTasks}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Typography variant="h6">Description</Typography> */}
                      <TextField
                        label="Enter description"
                        name="description"
                        type="text"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        fullWidth
                      />
                    </Stack>
                  </Grid>

                  <Grid item lg={4} md={5} sm={6} xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="h6">Start date</Typography>
                      <TextField
                        label=""
                        name="startDate"
                        type="date"
                        {...getFieldProps('startDate')}
                        error={Boolean(touched.startDate && errors.startDate)}
                      />
                    </Stack>
                  </Grid>

                  <Grid item lg={4} md={5} sm={6} xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="h6">End date</Typography>
                      <TextField
                        label=""
                        name="endDate"
                        type="date"
                        {...getFieldProps('endDate')}
                        error={Boolean(touched.endDate && errors.endDate)}
                      />
                    </Stack>
                  </Grid>

                  <Grid item lg={4} md={6} sm={6} xs={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      {/* <Typography variant="h6">Members</Typography> */}
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          name="members"
                          options={members}
                          getOptionLabel={(option) => option.fullname}
                          onChange={(e, value) => {
                            const temp = value.map((item, index) => {
                              return item._id;
                            });
                            setFieldValue('members', temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select members"
                              placeholder="Members"
                              onChange={handleChange}
                            // {...getFieldProps('members')}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" marginY={2} justifyContent="center" alignItems="center" spacing={2}>
                      <Button variant="contained" type="submit" sx={{ bgcolor: "#575151", ":hover": { background: "#423e3e" } }}>
                        Create
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
              {/* <Form onSubmit={handleSubmit}>
                  <Stack direction={'row'} spacing={5} paddingTop={5}>
                  
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      
                    </Stack>
                  </Stack>

                  <Stack direction={'row'} spacing={2} paddingTop={5} alignItems="center">
                  
                  </Stack>

                  <Stack direction={'row'} spacing={5} paddingTop={5}>
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <Typography variant="h6">Start date</Typography>
                      <TextField
                        label=""
                        name="startDate"
                        type="date"
                        {...getFieldProps('startDate')}
                        error={Boolean(touched.startDate && errors.startDate)}
                      />
                    </Stack>
                    <Stack direction={'row'} spacing={2} alignItems="center">
                      <Typography variant="h6">End date</Typography>
                      <TextField
                        label=""
                        name="endDate"
                        type="date"
                        {...getFieldProps('endDate')}
                        error={Boolean(touched.endDate && errors.endDate)}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" spacing={4} paddingTop={5} alignItems="center">
                    <Typography variant="h6">Members</Typography>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        name="members"
                        options={members}
                        getOptionLabel={(option) => option.fullname}
                        // defaultValue={[top100Films[13]]}
                        // filterSelectedOptions

                        onChange={(e, value) => {
                          const temp = value.map((item, index) => {
                            return item._id;
                          });
                          setFieldValue('members', temp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select members"
                            placeholder="Members"
                            onChange={handleChange}
                            // {...getFieldProps('members')}
                            error={Boolean(touched.members && errors.members)}
                          />
                        )}
                      />
                    </FormControl>
                  </Stack>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: '40px',
                      paddingBottom: '20px',
                    }}
                  >
                    <Button variant="contained" type="submit">
                      Create
                    </Button>
                  </div>
                </Form> */}
            </FormikProvider>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};
