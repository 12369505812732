import React, { useEffect, useState } from 'react';
import { Formik, Form, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import {
  Container,
  Typography,
  MenuItem,
  Box,
  Button,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Card,
  FormControl,
  Select,
  InputLabel,
  TextField,
  Stack,
  Grid,
  FormLabel,
  Autocomplete,
  CardContent,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { values } from 'lodash';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Page from '../components/Page';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const NewProject = () => {
  const [members, setMembers] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedTasks, setCheckedTasks] = useState([]);
  const [taskLists, setTaskLists] = useState([]);
  const [isExist, setIsExist] = useState();
  const [preSelectedCategory, setPreselectedCategory]= useState()
  // list members
  const navigate = useNavigate();
  const location = useLocation()
  const searchName = (e) => {
    const evalue = e.target.value.trim();
    if (!evalue) {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/project/exists`, {
        params: {
          name: evalue,
        },
      })
      .then((res) => {
        setIsExist(res.data.data.exist);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMembers = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/member/all-sorted`).then((res) => {
        setMembers(res.data.data.members);
        console.log('All MEMBERSSSS', res.data.data.members);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTaskLists = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/task/list/all`).then((res) => {
        setTaskLists(res.data.data.tasks);
        console.log('tasks', res.data.data.tasks);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartDateChange = (e, item, subTask) => {
    const newCheckedTasks = [...checkedTasks];
    const index = newCheckedTasks.findIndex((task) => task._id === item._id);
    const subTaskIndex = newCheckedTasks[index].subTask.findIndex((task) => task._id === subTask._id);
    newCheckedTasks[index].subTask[subTaskIndex].startDate = e.target.value;
    setCheckedTasks(newCheckedTasks);
    console.log('newCheckedTasks', newCheckedTasks);
  };

  const handleEndDateChange = (e, item, subTask) => {
    const newCheckedTasks = [...checkedTasks];
    const index = newCheckedTasks.findIndex((task) => task._id === item._id);
    const subTaskIndex = newCheckedTasks[index].subTask.findIndex((task) => task._id === subTask._id);
    newCheckedTasks[index].subTask[subTaskIndex].endDate = e.target.value;
    setCheckedTasks(newCheckedTasks);
    console.log('newCheckedTasks', newCheckedTasks);
  };

  const callAPIs = async () => {
    await Promise.all[(getMembers(), getTaskLists())];
  };

  useEffect(() => {
    callAPIs();
  }, []);

  

  const ProjectSchema = Yup.object({
    name: Yup.string().required('Required'),
    category: Yup.string().required('Required'),
    description: Yup.string(),
    startDate: Yup.date(),
    endDate: Yup.date(),
    location: Yup.string().required('Location is required'),
    members: Yup.array(),
    manager: Yup.string().required('Manager is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      category: "",
      description: '',
      startDate: Moment().format('YYYY-MM-DD'),
      endDate: '',
      location: '',
      members: [],
      manager: '',
      tasks: [],
    },
    validationSchema: ProjectSchema,
    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .post(`${process.env.REACT_APP_API_URL}/project`, values)
          .then((res) => {
            toast.success(res.data.message);
            navigate(-1);
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('tasks', checkedTasks);
  }, [checkedTasks]);

  const handleCheckBox = (event, item) => {
    if (event.target.checked) {
      const newCheckedTasks = [...checkedTasks, item];
      setCheckedTasks(newCheckedTasks);
    } else {
      const newCheckedTasks = checkedTasks.filter((task) => task._id !== item._id);
      setCheckedTasks(newCheckedTasks);
    }
  };

  useEffect(() => {
    console.log(checkedTasks);
  }, [checkedTasks]);

  useEffect(() => {
    if(location && location.state){
      setPreselectedCategory(location.state.category)
      console.log(location.state.category)
      formik.setFieldValue("category",location.state.category)
    }
  }, [location]);

  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, isSubmitting, getFieldProps } = formik;

  return (
    <>
      <Page title="Dashboard : new project">
        <Container>
          <Card>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px' }}>
              <Typography variant="h4" marginTop={2} marginLeft={2}>
                {' '}
                Create New Project
              </Typography>
              <Link to={`/dashboard/project`} style={{ textDecoration: 'none' }}>
                <CloseIcon />
              </Link>
            </div>
            <CardContent>
              <Grid container justifyContent="center" xs={12}>
                <FormikProvider value={formik}>
                  <Box width="100%">
                    <Form noValidate width="100%" onSubmit={handleSubmit}>
                      <Grid direction="row" rowSpacing={2} container xs={12}>
                        <Grid item spacing={2} xs={12}>
                          <Grid container justifyContent="flex-start" spacing={2} xs={12}>
                            <Grid item direction="row" xs={12} sm={12} md={7} lg={8}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography> Name</Typography> */}
                                <TextField
                                  label="Enter project name"
                                  name="name"
                                  type="text"
                                  fullWidth
                                  {...getFieldProps('name')}
                                  error={Boolean(touched.name && errors.name)}
                                  onKeyUp={searchName}
                                  helperText={
                                    (isExist === true && <p style={{ color: 'red' }}> Project already exist</p>) ||
                                    (isExist === false && <p style={{ color: 'green' }}>Project name available</p>)
                                  }
                                />
                              </Stack>
                            </Grid>
                            <Grid item direction="row" xs={12} sm={6} md={5} lg={4}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography>Category</Typography> */}
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    name="category"
                                    value={preSelectedCategory}
                                    label="Select Category"
                                    {...getFieldProps('category')}
                                    onChange={formik.handleChange}
                                  >
                                    <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                                    <MenuItem value="COMMERCIAL_INTERIOR">Commercial Interior</MenuItem>
                                    <MenuItem value="CULTURAL">Cultural</MenuItem>
                                    <MenuItem value="EDUCATIONAL">Educational</MenuItem>
                                    <MenuItem value="HOSPITALITY">Hospitality</MenuItem>
                                    <MenuItem value="RESIDENTIAL">Residential</MenuItem>
                                  </Select>
                                </FormControl>
                              </Stack>
                            </Grid>

                     
                            <Grid item direction="row" xs={12} sm={6} md={6} lg={8}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography>Location</Typography> */}
                                <TextField
                                  fullWidth
                                  label="Enter location"
                                  name="location"
                                  type="text"
                                  {...getFieldProps('location')}
                                  error={Boolean(touched.location && errors.location)}
                                />
                              </Stack>
                            </Grid>
                            <Grid item direction="row" xs={12} sm={6} md={3} lg={2}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography>Start date</Typography> */}
                                <TextField
                                  label="Start date"
                                  fullWidth
                                  defaultValue={Moment().format('YYYY-MM-DD')}
                                  InputLabelProps={{ shrink: true }}
                                  name="startDate"
                                  type="date"
                                  {...getFieldProps('startDate')}
                                  error={Boolean(touched.startDate && errors.startDate)}
                                />
                              </Stack>
                            </Grid>
                            <Grid item direction="row" xs={12} sm={6} md={3} lg={2}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography>End date</Typography> */}
                                <TextField
                                  label="End date"
                                  fullWidth
                                  InputLabelProps={{ shrink: true }}
                                  name="endDate"
                                  type="date"
                                  {...getFieldProps('endDate')}
                                  error={Boolean(touched.endDate && errors.endDate)}
                                />
                              </Stack>
                            </Grid>
                            <Grid item direction="row" xs={12} sm={6} md={6} lg={4}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                <Box width="100%">
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      name="manager"
                                      fullWidth
                                      options={members}
                                      getOptionLabel={(option) => option.fullname}
                                      onChange={(e, value) => {
                                        setFieldValue('manager', value._id);
                                        console.log('value', value);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select manager"
                                          placeholder="Manager"
                                          onChange={handleChange}
                                          error={Boolean(touched.manager && errors.manager)}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Box>
                              </Stack>
                            </Grid>
                            <Grid item direction="row" xs={12} sm={6} md={6} lg={4}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                <Box width="100%">
                                  <FormControl fullWidth>
                                    <Autocomplete
                                      multiple
                                      id="multiple-limit-tags"
                                      name="members"
                                      options={members}
                                      getOptionLabel={(option) => option.fullname}
                                      onChange={(e, value) => {
                                        const temp = value.map((item, index) => {
                                          return item._id;
                                        });
                                        setFieldValue('members', temp);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Select members"
                                          placeholder="Members"
                                          onChange={handleChange}
                                          // {...getFieldProps('members')}
                                          error={Boolean(touched.members && errors.members)}
                                        />
                                      )}
                                    />
                                  </FormControl>
                                </Box>
                              </Stack>
                            </Grid>

                            <Grid item direction="row" xs={12} sm={12} md={12} lg={12}>
                              <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                {/* <Typography>Location</Typography> */}
                                <TextField
                                  fullWidth
                                  label="Enter project description"
                                  name="description"
                                  type="text"
                                  {...getFieldProps('description')}
                                  error={Boolean(touched.description && errors.description)}
                                />
                              </Stack>
                            </Grid>

                            <Grid item xs={12} direction={'row'}>
                              <Box sx={{ border: 'solid 0.5px #E8E8E8', borderRadius: '10px', padding: 1 }}>
                                <Grid item xs={12} direction={'row'}>
                                  <Typography color={'gray'}>Select Task Categories</Typography>
                                </Grid>
                                <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                                  {taskLists?.map((item, key) => (
                                    <FormControlLabel
                                      key={key}
                                      sx={{ marginRight: '20px' }}
                                      control={
                                        <Checkbox
                                          checked={checkedTasks.some((task) => task._id === item._id)}
                                          key={key}
                                          onChange={(e) => handleCheckBox(e, item)}
                                          value={item._id}
                                        />
                                      }
                                      label={item.name}
                                    />
                                  ))}
                                </FormGroup>
                              </Box>
                            </Grid>

                            <Grid item xs={12} direction={'row'}>
                              <Box sx={{ border: 'solid 0.5px #E8E8E8', borderRadius: '10px', padding: 1 }}>
                                <Grid container>
                                  <Grid item xs={12} direction={'row'}>
                                    <Typography color={'gray'}>Select Dates</Typography>
                                  </Grid>
                                  {checkedTasks?.map((item, key) => (
                                    <Grid
                                      container
                                      key={key}
                                      mb={4}
                                      padding={1}
                                      sx={{ border: 'solid 0.5px #E8E8E8', borderRadius: '10px' }}
                                    >
                                      <Grid item xs={12} direction={'row'}>
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{`${key + 1}. ${
                                          item.name
                                        }`}</Typography>
                                      </Grid>
                                      {item.subTask?.map((subTask, indexKey) => (
                                        <Grid key={indexKey} item xs={12} md={6} direction={'row'} padding={2}>
                                          <Stack spacing={2} alignItems="start" width="100%" direction="column">
                                            <Typography sx={{ fontWeight: 'bold' }}>{subTask.name}</Typography>
                                            <Stack spacing={2} alignItems="center" width="100%" direction="row">
                                              <TextField
                                                label="Start date"
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleStartDateChange(e, item, subTask)}
                                                name="startDate"
                                                type="date"
                                              />
                                              <TextField
                                                label="End date"
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => handleEndDateChange(e, item, subTask)}
                                                name="endDate"
                                                type="date"
                                              />
                                            </Stack>
                                          </Stack>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  ))}
                                </Grid>
                              </Box>
                            </Grid>

                            <Grid item direction="row" xs={12}>
                              <Stack
                                marginTop={1}
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                                direction="row"
                              >
                                <Button
                                  sx={{ bgcolor: '#136586', ':hover': { background: '#105470' }, color: 'white' }}
                                  className="btn btn-dark mt-3"
                                  variant="contained"
                                  size="large"
                                  type="submit"
                                >
                                  Create
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  </Box>
                </FormikProvider>
              </Grid>

              {/* <Grid style={{ marginTop: '30px' }}>
                    <Stack direction={'row'} spacing={2} sx={{ marginLeft: '200px' }} alignItems="center">
                  
                      <Typography variant="h6">Category</Typography>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="category"
                          label="Select Category"
                          sx={{ width: '50%', height: '55px' }}
                          {...getFieldProps('category')}
                          onChange={formik.handleChange}
                        >
                          <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                          <MenuItem value="COMMERCIAL_INTERIOR">Commercial Interior</MenuItem>
                          <MenuItem value="CULTURAL">Cultural</MenuItem>
                          <MenuItem value="EDUCATIONAL">Educational</MenuItem>
                          <MenuItem value="HOSPITALITY">Hospitality</MenuItem>
                          <MenuItem value="RESIDENTIAL">Residential</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>

                    <Stack
                      direction="row"
                      marginTop={'20px'}
                      spacing={2}
                      alignItems="center"
                      sx={{ marginLeft: '150px' }}
                    >
                      <Typography variant="h6">Description</Typography>
                     
                    </Stack>

                    <Stack
                      direction="row"
                      marginTop={'20px'}
                      spacing={2}
                      alignItems="center"
                      sx={{ marginLeft: '162px' }}
                    >
                    
                      <Typography variant="h6">End date</Typography>
                      <TextField
                        label=""
                        name="endDate"
                        type="date"
                        {...getFieldProps('endDate')}
                        error={Boolean(touched.endDate && errors.endDate)}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      alignItems="center"
                      marginTop={'20px'}
                      spacing={2}
                      sx={{ marginLeft: '175px' }}
                    >
                      <Typography variant="h6">Location</Typography>
                      <TextField
                        fullWidth
                        label="Enter location"
                        name="location"
                        type="text"
                        {...getFieldProps('location')}
                        error={Boolean(touched.location && errors.location)}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      marginTop={'20px'}
                      alignItems="center"
                      spacing={2}
                      sx={{ marginLeft: '170px' }}
                    >
                      <Typography variant="h6">Manager</Typography>
                    
                      <Typography variant="h6">Members</Typography>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          name="members"
                          options={members}
                          getOptionLabel={(option) => option.fullname}
                      

                          onChange={(e, value) => {
                            const temp = value.map((item, index) => {
                              return item._id;
                            });
                            setFieldValue('members', temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select members"
                              placeholder="Members"
                              onChange={handleChange}
                              // {...getFieldProps('members')}
                              error={Boolean(touched.members && errors.members)}
                            />
                          )}
                        />
                      </FormControl>
                    </Stack>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                      <Button className="btn btn-dark mt-3" variant="contained" width="50px" type="submit">
                        Create
                      </Button>
                    </div>
                  </Form>
                </Grid>
              </FormikProvider>
            </Grid> */}
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default NewProject;
