import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Dialog } from '@mui/material';
// component
import UserPopup from '../../../components/UserPopup';
import Iconify from '../../../components/Iconify';
import { EditUser } from '../../../components/EditUser';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ item, getUsers }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleModal = () => {
    console.log('clicked');
    setOpenModal(!openModal);
  };

  return (
    <>
      <IconButton onClick={handleModal}>
        <Iconify icon="eva:edit-fill" width={24} height={24} />
      </IconButton>
      <Dialog aria-labelledby="customized-dialog-title" open={openModal}>
        <UserPopup closePopup={handleModal} menu={setIsOpen}>
          <EditUser getUsers={getUsers} item={item} closePopup={handleModal} />
        </UserPopup>
      </Dialog>
    </>
  );
}
