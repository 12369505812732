import { faker } from '@faker-js/faker';
import { useEffect, useState, useRef } from 'react';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Card,
  CardHeader,
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
} from '@mui/material';
import axios from 'axios';
// components
import Graph from '../components/chart/Graph';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import UserCard from '../components/UserCard';
import Searchbar from '../layouts/dashboard/Searchbar';
import Filter from '../components/MemberFilter';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  const [projectCategories, setProjectCategories] = useState([]);
  const [members, setMembers] = useState([]);
  // const [clickedMember, setClickedMember] = useState();
  const [clickedMember, setClickedMember] = useState({ name: '', userId: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  const [searchName, setSearchName] = useState('');
  const [accType,setAccType] = useState("PARTNER")
  const handleScrollClick = (username, _id) => {
    setClickedMember({ name: username, userId: _id });
    getCategory(_id).then(() => {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    });
    console.log('clicked user ==>', _id, username);
  };
  const ref = useRef(null);
  const getCategory = async (_id) => {
    try {
      setIsLoading1(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/category/projects`, {
        params: { userId: _id },
      });
      console.log(res);
      setProjectCategories(res.data.data.categories);
      setIsLoading1(false);
    } catch (error) {
      console.log(error);
      setIsLoading1(false);
    }
  };
  const getAllUser = async (options) => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/member/allthemembers`, {
        params: options,
      });
      console.log(res);
      setMembers(res.data.data.members);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getCategory();
    getAllUser({accType:"PARTNER"});
  }, []);

  const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
    '& fieldset': {
      borderWidth: `1px !important`,
      borderColor: `${theme.palette.grey[500_32]} !important`,
    },
  }));

  const handleSearch = (searchName, accType) => {
    console.log('search ==>', searchName);
    // getAllUser(searchName, accType);
    // setSearchName(e.target.value);
  };

  const applyFilters = (filter) => {
    const temp = {};
    if (filter.name.length) {
      temp.name = filter.name;
    }
    if (filter.accType.length) {
      temp.accType = filter.accType;
      setAccType(filter.accType)
    }
    getAllUser(temp);
  };
  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back
          </Typography>
          {isLoading ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',

                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <Card sx={{ paddingX: 2, paddingBottom: 2, marginTop: '50px' }}>
              <Stack>
                <Filter applyFilters={applyFilters} />
                <Typography variant="h4" textAlign={'center'} marginBottom="20px">
                  {accType}S
                </Typography>
              </Stack>

              {/* <Searchbar handleSearch={handleSearch} searchTerm={searchName} /> */}
              {/* <SearchStyle
                value={searchName}
                onChange={onFilterName}
                placeholder="Search user..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              /> */}

              {!members.length > 0 && (
                <Typography variant="body1" textAlign="center">
                  No Members Found
                </Typography>
              )}
              <Box sx={{ overflowY: 'auto', maxHeight: '600px' }}>
                {/* ifno members foound in search  */}

                <Grid container spacing={3}>
                  {members.length > 0 &&
                    members?.map((member, index) => {
                      return (
                        <Grid item xs={12} sm={4} md={3}>
                          <UserCard user={member} handleScrollClick={handleScrollClick} />
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Card>
          )}
          <div ref={ref} style={{ scrollMargin: '50px' }} />
          {isLoading1 ? (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',

                alignItems: 'center',
                height: '50vh',
              }}
            >
              <CircularProgress />
            </div>
          ) : (  <Card sx={{ padding: 2, marginTop: '50px' }}>
            <Typography variant="h4" textAlign={'center'} margin="20px">
              CATEGORIES {clickedMember.name && `OF ${clickedMember?.name.toUpperCase()} `}
            </Typography>
            <Grid container spacing={3}>
              <AppWidgetSummary data={projectCategories} userId={clickedMember.userId} />
            </Grid>

            {projectCategories && (
              <Card sx={{ padding: 2, marginTop: 4 }}>
                <Graph data={projectCategories} />
              </Card>
            )}
            <Typography variant="body1" textAlign={'center'} margin="20px">
              Category X Projects
            </Typography>
          </Card>
          )}
        </Container>
      </Page>
    </>
  );
}
