import Moment from 'moment';
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Box,
  CardMedia,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import fileImage from '../assets/file.png';
import '../assets/style.css';

export default function CardForAttachment({ file, taskId, callApis }) {
  console.log(file);
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteFile = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/task/admin/removefile`, {
        taskId,
        attachmentId: file?._id,
      });
      console.log('delete response', res);
      setOpen(false);
      callApis();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  const fileType = [
    'image/apng',
    'image/bmp',
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/png',
    '	image/svg+xml	',
    'image/tiff',
    'image/webp',
  ];
  const saveFile = () => {
    window.location.href = `${file.downloadURL}`;
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete File'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ color: '#475985', ':hover': { bgcolor: '#DEE2ED' } }} onClick={handleDeleteFile} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F0F2F7' }}>
          <CardContent sx={{ flex: '1 0 auto', minWidth: '210px', maxWidth: '210px' }}>
            <Typography
              component="div"
              variant="h6"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'noWrap' }}
            >
              {file.isAdmin ? 'Admin' : file.userId.fullname.charAt(0).toUpperCase() + file.userId.fullname.slice(1)}
            </Typography>
            <Typography component="div" variant="p">
              {Moment(file.createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              component="div"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'noWrap' }}
            >
              {file.originalName}
            </Typography>
          </CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, pl: 1, pb: 1 }}>
            <Button
              size="small"
              style={{ color: '#475985' }}
              variant="outlined"
              startIcon={<RemoveRedEyeOutlinedIcon />}
              onClick={() => window.open(file.fileURL, '_blank')}
            >
              View
            </Button>
            <Button size="small" style={{ color: '#475985' }} variant="outlined" onClick={saveFile}>
              Download
            </Button>
            <IconButton onClick={handleClickOpen} style={{ color: '#4d9bfb' }} aria-label="delete">
              <DeleteIcon style={{ fontSize: '21px', color: '#475985' }} />
            </IconButton>
          </Box>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          <IconButton aria-label="previous">
            {theme.direction === 'rtl' ? <SkipNextIcon /> : <SkipPreviousIcon />}
          </IconButton>
          <IconButton aria-label="play/pause">
            <PlayArrowIcon sx={{ height: 38, width: 38 }} />
          </IconButton>
          <IconButton aria-label="next">
            {theme.direction === 'rtl' ? <SkipPreviousIcon /> : <SkipNextIcon />}
          </IconButton>
        </Box> */}
        </Box>
        {fileType.includes(file.mimeType) ? (
          <CardMedia
            component="img"
            className="imge"
            sx={{ width: 151, height: 168 }}
            image={file.fileURL}
            alt="Live from space album cover"
          />
        ) : (
          <CardMedia
            component="img"
            className="imge"
            sx={{ width: 151 }}
            src={fileImage}
            alt="Live from space album cover"
            style={{ objectFit: 'contain', padding: '30px', backgroundColor: '#eff1f6', borderLeft: '1px solid white' }}
          />
        )}
      </Card>
    </>
  );
}
