import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Card,
  Box,
  Stack,
  TextField,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Zoom,
  Autocomplete,
  FormControl,
  CircularProgress,
  Divider,
  List,
  ListItemText,
  ListItemButton,
  Collapse,
  ListItem,
  Avatar,
  ListItemAvatar,
  Badge,
  CardContent,
  IconButton,
  Popover,
  Menu,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { CalendarMonthOutlined, Close, Delete, Edit, MoreVert, RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { Icon } from '@iconify/react';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CategoryIcon from '@mui/icons-material/Category';
import axios from 'axios';
import Moment from 'moment';
import { Form, useFormik, FormikProvider } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import avatar from '../assets/avatar.png';
import ChangeManagerPopup from '../components/ChangeManagerPopup';
import Page from '../components/Page';
import { ListTask } from './ListTask';
import { TaskDatePopup } from './TaskDatePopup';
import ProjectDescriptionCard from '../components/ProjectDescriptionCard';

export const ViewProject = () => {
  const [getProject, setGetProject] = useState({});
  const [nonmembers, setNonmembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [description, setDescription] = useState('');
  const [open2, setOpen2] = useState({ flag: false, id: null });
  const [openCollapse, setOpenCollapse] = useState(false);
  const { id } = useParams();
  const [viewDates, setViewDates] = useState(false);
  const navigate = useNavigate();
  const [open6, setOpen6] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open7, setOpen7] = useState(false);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [selectedPopup, setSelectedPopup] = useState('');
  const [openNameEdit, setOpenNameEdit] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [openDelete, setOpenDelete] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [alreadyExists, setAlreadyExists] = useState(false);

  const handleOpenPopoverManager = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen6(true);
    event.stopPropagation();
  };

  const handleClosePopoverManager = (event) => {
    setAnchorEl(null);
    setOpen6(false);
  };
  const handleOpenPopoverMember = (event, id) => {
    setAnchorEl1(event.currentTarget);
    setOpen7(true);
    setSelectedPopup(id);
    event.stopPropagation();
  };

  const handleClosePopoverMember = (event) => {
    setAnchorEl1(null);
    setOpen7(false);
  };

  const formik = useFormik({
    initialValues: {
      userIds: [],
      projectId: id,
    },

    onSubmit: async () => {
      console.log('values', values);
      try {
        await axios
          .put(`${process.env.REACT_APP_API_URL}/project/addmembers`, values)
          .then((res) => {
            toast.success(res.data.message);
            handleClose();
            callApis();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } catch (error) {
        console.log(error);
        return false;
      }
    },
  });

  const handleClick = () => {
    setOpen(!open);
  };

  const getProjectById = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/`, { params: { projectId: id } });
      console.log('res', res.data.data.project);
      setGetProject(res.data.data.project);
      setDescription(res.data.data.project.description);
      setProjectName(res.data.data.project.name);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getNonmembers = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/nonmembers`, { params: { projectId: id } });
      console.log('res', res);
      setNonmembers(res.data.data.data.nonmembers);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const handleDialog = () => {
    setOpen1(true);
  };
  const handleClose = (e, flag = '') => {
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
    if (flag) {
      callApis();
    }
  };

  const handleDeletePopup = (id) => {
    setOpen2({ flag: true, id });
  };
  const handleMnagerPopup = () => {
    setOpen3(true);
  };

  const handleCloseNameEdit = () => {
    setOpenNameEdit(false);
    handleClose2();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    handleClose2();
  };

  const handleNameEdit = async () => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        name: projectName,
      });
      toast.success(res.data.message);
      handleClose2();
      setOpenNameEdit(false);
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/project`, {
        params: { projectId: id, deletePassword },
      });
      toast.success(res.data.message);
      handleClose2();
      navigate('/dashboard/project');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const removeMember = async (userId) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/removemember`, {
        userId: open2.id,
        projectId: id,
      });
      toast.success(res.data.message);
      setOpen2({ flag: false, id: null });
      callApis();
    } catch (error) {
      console.log(error);
    }
  };

  const callApis = async () => {
    setIsLoading(true);
    await getProjectById();
    await getNonmembers();
    setIsLoading(false);
  };

  const checkIfAlreadyExists = async (projectName) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/project/exists/different`, {
        params: { name: projectName, projectId: id },
      });
      console.log('res', res);
      setAlreadyExists(res.data.data.exist);
    } catch (error) {
      console.log(error);
    }
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
    checkIfAlreadyExists(e.target.value);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    callApis();
  }, []);
  const { errors, touched, handleSubmit, handleChange, setFieldValue, values, getFieldProps } = formik;
  return (
    <Page title="Dashboard : view_project ">
      <Container>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <Grid>
            <Dialog
              open={open1}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Stack direction="row" justifyContent={'space-between'} marginX={-1}>
                  {'Add Members'}
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={handleClose} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </DialogTitle>
              <DialogContent>
                <FormikProvider value={formik}>
                  <Form onSubmit={handleSubmit}>
                    <Stack direction="column">
                      <FormControl sx={{ m: 1, width: { sm: '350px', xs: '250px' } }}>
                        <Autocomplete
                          multiple
                          id="multiple-limit-tags"
                          name="userIds"
                          options={nonmembers}
                          getOptionLabel={(option) => option.fullname}
                          onChange={(e, value) => {
                            const temp = value.map((item, index) => {
                              return item._id;
                            });
                            setFieldValue('userIds', temp);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select members"
                              placeholder="Members"
                              fullWidth
                              onChange={handleChange}
                              error={Boolean(touched.userIds && errors.userIds)}
                            />
                          )}
                        />
                      </FormControl>
                      <Stack mt={2} padding={1} spacing={2} direction="row" justifyContent="center">
                        <Button
                          variant="contained"
                          type="submit"
                          sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' }, width: '100px' }}
                        >
                          Add
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </DialogContent>
            </Dialog>
            <ChangeManagerPopup popup={open3} handleClose={handleClose} />
            <Dialog
              open={open2.flag}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Remove member'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to remove this member from Project?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={handleClose}>
                  No
                </Button>
                <Button onClick={removeMember} sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openNameEdit}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Edit Project Name'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <TextField
                    fullWidth
                    label="Project Name"
                    name="projectName"
                    sx={{
                      minWidth: {
                        xs: '250px',
                        sm: '350px',
                      },
                      mt: 1,
                    }}
                    onChange={(e) => handleProjectNameChange(e)}
                    value={projectName}
                    error={alreadyExists}
                    helperText={alreadyExists ? 'Project name already exists' : ''}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={handleCloseNameEdit}>
                  Cancel
                </Button>
                <Button
                  onClick={handleNameEdit}
                  sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }}
                  autoFocus
                  disabled={alreadyExists}
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Delete Project'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <TextField
                    fullWidth
                    type="password"
                    label="Password"
                    name="deletePassword"
                    sx={{
                      minWidth: {
                        xs: '250px',
                        sm: '350px',
                      },
                      mt: 1,
                    }}
                    onChange={(e) => setDeletePassword(e.target.value)}
                    value={deletePassword}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} onClick={handleCloseDelete}>
                  Cancel
                </Button>
                <Button onClick={handleDelete} sx={{ color: '#575151', ':hover': { bgcolor: '#E9E2E2' } }} autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Card
              sx={{
                padding: '20px',
                marginBottom: '5px',
                bgcolor: '#ded3d3',
                position: 'relative',
              }}
            >
              <Typography variant="h4" textAlign="center">
                {getProject?.name?.toUpperCase()}
              </Typography>
              <IconButton
                aria-controls="project-options"
                aria-haspopup="true"
                onClick={handleClick2}
                sx={{
                  position: 'absolute',
                  top: '50%', // Vertically center the IconButton
                  transform: 'translateY(-50%)', // Adjust for vertical alignment
                  right: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MoreVert />
              </IconButton>
              <Menu id="project-options" anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={handleClose2}>
                <MenuItem onClick={() => setOpenNameEdit(true)}>
                  <Edit sx={{ marginRight: 1 }} />
                  Edit
                </MenuItem>
                <MenuItem onClick={() => setOpenDelete(true)}>
                  <Delete sx={{ marginRight: 1 }} />
                  Delete
                </MenuItem>
              </Menu>
            </Card>

            <Card sx={{ padding: '5px' }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'space-between', sm: 'flex-end' },
                  margin: '5px',
                }}
              >
                <IconButton variant="contained" sx={{ color: '#575151' }} onClick={() => setOpen4((open4) => !open4)}>
                  <CalendarMonthOutlined />
                </IconButton>
                <Button
                  variant="contained"
                  sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
                  onClick={() => setOpenCollapse((openCollapse) => !openCollapse)}
                  endIcon={openCollapse ? <ExpandLess /> : <ExpandMore />}
                >
                  Details
                </Button>
                <Stack direction="row" spacing={1} alignItems="center">
                  {/* <Button
                    startIcon={<AddCircleOutlineSharpIcon />}
                    onClick={handleDialog}
                    variant="contained"
                    sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
                  >
                    Members
                  </Button> */}
                  <Link to={`/dashboard/project/create/${id}`} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#575151', ':hover': { background: '#423e3e' } }}
                      startIcon={<AddCircleOutlineSharpIcon />}
                    >
                      Task
                    </Button>
                  </Link>
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <CloseRoundedIcon onClick={() => navigate(-1)} sx={{ color: 'black', cursor: 'pointer' }} />
                  </Tooltip>
                </Stack>
              </Stack>
              <Collapse in={openCollapse}>
                <Grid
                  style={{ padding: '5px' }}
                  container
                  sx={12}
                  mb={2}
                  direction="row"
                  // </Box>justify='flex-start'
                  // alignItem='flex-start'
                >
                  <ProjectDescriptionCard project={getProject} getProjectById={getProjectById} id={id} />

                  <Grid container lg={4} md={5} sm={12} xs={12}>
                    <Box width="100%">
                      <Grid item md={12} sm={12} xs={12}>
                        <Card
                          style={{
                            padding: '10px',
                            marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                            marginBottom: '10px',
                            backgroundColor: '#ede8e8',
                          }}
                        >
                          <Typography variant="h7" sx={{ fontWeight: 'bold' }}>
                            Manager
                          </Typography>
                          <Divider style={{ padding: '5px' }} />
                          <ListItem fullWidth>
                            <ListItemAvatar
                              sx={{
                                cursor: 'pointer',
                              }}
                              onClick={(e) => handleOpenPopoverManager(e)}
                            >
                              {!getProject?.manager?.profilePic ? (
                                <Avatar alt="Remy Sharp" src={avatar} />
                              ) : (
                                <Avatar alt="Remy Sharp" src={getProject?.manager?.profilePic} />
                              )}
                            </ListItemAvatar>
                            <Tooltip TransitionComponent={Zoom} title={getProject?.manager.email}>
                              <ListItemText>
                                <Typography variant="h6">{getProject?.manager.fullname.toUpperCase()}</Typography>
                              </ListItemText>
                            </Tooltip>
                            <Stack direction="column" alignItems="flex-end">
                              <ListItemButton onClick={handleMnagerPopup} alignItems="flex-end">
                                <ModeEditOutlineOutlinedIcon />
                              </ListItemButton>
                            </Stack>
                          </ListItem>

                          <Popover
                            open={open6}
                            anchorEl={anchorEl}
                            onClose={handleClosePopoverManager}
                            disableRestoreFocus
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                p: 2,
                                maxWidth: 280,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                              }}
                            >
                              <IconButton
                                onClick={handleClosePopoverManager}
                                sx={{ position: 'absolute', top: 0, right: 0 }}
                              >
                                <Close />
                              </IconButton>
                              <Avatar
                                src={
                                  getProject?.manager?.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'
                                }
                                alt="Avatar"
                                sx={{ width: 200, height: 200 }}
                              />
                              <Typography
                                sx={{
                                  lineHeight: 1.2,
                                  fontWeight: 'bold',
                                }}
                              >
                                {getProject?.manager.fullname.toUpperCase()}
                              </Typography>
                              <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                {getProject?.manager.email}
                              </Typography>
                              <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                {getProject?.manager.accType}
                              </Typography>
                            </Box>
                          </Popover>
                        </Card>
                      </Grid>

                      <Grid item md={12} sm={12} xs={12}>
                        <Box>
                          <Card
                            style={{
                              padding: '10px',
                              marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
                              backgroundColor: '#ede8e8',
                              maxHeight: '306px',
                              overflowY: 'scroll',
                            }}
                          >
                            <ListItemButton onClick={handleClick}>
                              <ListItemText style={{ fontWeight: 'bold' }} primary="Members" />
                              {!open ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={!open}>
                              <List component="div" disablePadding>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{
                                    width: '100%',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    ':hover': { backgroundColor: '#ccc' },
                                  }}
                                  onClick={handleDialog}
                                >
                                  <Box
                                    sx={{
                                      height: '30px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      width: '150px',
                                    }}
                                  >
                                    <AddCircleOutlineSharpIcon sx={{ marginRight: '10px' }} />
                                    <Typography sx={{ fontWeight: 'bold' }}>Add Member</Typography>
                                  </Box>
                                </Stack>
                                {getProject?.members.map((mdata, id) => (
                                  <ListItem key={id} fullWidth>
                                    <ListItemAvatar
                                      onClick={(e) => handleOpenPopoverMember(e, id)}
                                      sx={{
                                        cursor: 'pointer',
                                      }}
                                    >
                                      <Badge
                                        invisible={!mdata.isBlocked}
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        badgeContent={
                                          <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                        }
                                      >
                                        {!mdata.profilePic ? (
                                          <Avatar alt="Remy Sharp" src={avatar} />
                                        ) : (
                                          <Avatar alt="Remy Sharp" src={mdata.profilePic} />
                                        )}
                                      </Badge>
                                    </ListItemAvatar>
                                    <Tooltip TransitionComponent={Zoom} title={mdata.email}>
                                      <ListItemText
                                        primary={mdata.fullname.charAt(0).toUpperCase() + mdata.fullname.slice(1)}
                                      />
                                    </Tooltip>
                                    <Stack direction="column" alignItems="flex-end">
                                      <ListItemButton
                                        onClick={() => handleDeletePopup(mdata._id)}
                                        alignItems="flex-end"
                                      >
                                        <DeleteOutlinedIcon />
                                      </ListItemButton>
                                    </Stack>

                                    <Popover
                                      open={open7 && id === selectedPopup}
                                      anchorEl={anchorEl1}
                                      onClose={handleClosePopoverMember}
                                      disableRestoreFocus
                                      anchorOrigin={{
                                        vertical: 'center',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          p: 2,
                                          maxWidth: 280,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        <IconButton
                                          onClick={handleClosePopoverMember}
                                          sx={{ position: 'absolute', top: 0, right: 0 }}
                                        >
                                          <Close />
                                        </IconButton>
                                        <Avatar
                                          src={mdata.profilePic || 'https://www.w3schools.com/howto/img_avatar.png'}
                                          alt="Avatar"
                                          sx={{ width: 200, height: 200 }}
                                        />
                                        <Typography
                                          sx={{
                                            lineHeight: 1.2,
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          {mdata.fullname.toUpperCase()}
                                        </Typography>
                                        <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                          {mdata.email}
                                        </Typography>
                                        <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                                          {mdata.accType}
                                        </Typography>
                                      </Box>
                                    </Popover>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          </Card>
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Collapse>
              <Grid>
                <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: { sx: '5px', sm: '10px' } }}>
                  <Typography variant="h5">TASKS</Typography>
                </Box>
              </Grid>
              <Box>
                <ListTask getProject={getProjectById} />
              </Box>
            </Card>
          </Grid>
        )}
        {open4 && <TaskDatePopup setOpen4={setOpen4} />}
      </Container>
    </Page>
  );
};
