import PropTypes from 'prop-types';

// material
import { alpha, styled } from '@mui/material/styles';
import { useState } from 'react';
import Close from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { Box, Card, Grid, Avatar, Tooltip, Divider, Typography, IconButton, Popover } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import Iconify from './Iconify';
import SvgIconStyle from '../utils/SvgIconStyle';

// utils

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    fullname: 'Facebook',
    icon: <Iconify icon={'facebookFill'} width={20} height={20} color="#1877F2" />,
  },
  {
    fullname: 'Instagram',
    icon: <Iconify icon={'instagramFilled'} width={20} height={20} color="#D7336D" />,
  },
  {
    fullname: 'Linkedin',
    icon: <Iconify icon={'linkedinFill'} width={20} height={20} color="#006097" />,
  },
  {
    fullname: 'Twitter',
    icon: <Iconify icon={'twitterFill'} width={20} height={20} color="#1C9CEA" />,
  },
];

const CardMediaStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  // paddingTop: 'calc(20% * 9 / 16)',
  paddingTop: '40px',

  '&:before': {
    top: 0,
    zIndex: 9,
    content: "''",
    width: '100%',
    height: '100%',
    position: 'absolute',
    backdropFilter: 'blur(3px)',
    WebkitBackdropFilter: 'blur(3px)', // Fix on Mobile
    borderTopLeftRadius: theme.shape.borderRadiusMd,
    borderTopRightRadius: theme.shape.borderRadiusMd,
    // backgroundColor: alpha(theme.palette.primary.darker, 0.72),
    backgroundColor: '#0f5673',
  },
}));

const CoverImgStyle = styled('img')({
  top: 0,
  zIndex: 8,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

function InfoItem(number) {
  return (
    <Grid item xs={4}>
      <Typography variant="caption" sx={{ mb: 0.5, color: 'text.secondary', display: 'block' }}>
        Follower
      </Typography>
      {/* <Typography variant="subtitle1">{fShortenNumber(number)}</Typography> */}
    </Grid>
  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function UserCard({ user, handleScrollClick, ...other }) {
  const { fullname, email, _id, cover, position, follower, totalPost, avatarUrl, following, profilePic, accType } =
    user;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    event.stopPropagation();
  };

  const handleClosePopover = (event) => {
    setAnchorEl(null);
    setOpen(false);
    event.stopPropagation();
  };

  const navigate = useNavigate();
  return (
    <Card
      {...other}
      // onClick={() => navigate(`/dashboard/project/?userId=${_id}`)}
      sx={{ height: '160px', backgroundColor: '#f0f2f7' }}
    >
      <Box
        onClick={handleOpenPopover}
        sx={{
          cursor: 'pointer',
        }}
      >
        {' '}
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 144,
              height: 62,
              zIndex: 10,
              bottom: -26,
              position: 'absolute',
              backgroundColor: '#f0f2f7',
            }}
          />
          {!profilePic ? (
            <Avatar
              alt={fullname}
              src={avatarUrl}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                position: 'absolute',
                transform: 'translateY(-50%)',
                backgroundColor: '#0f5673',
              }}
            />
          ) : (
            <Avatar
              alt={fullname}
              src={profilePic}
              sx={{
                width: 64,
                height: 64,
                zIndex: 11,
                position: 'absolute',
                transform: 'translateY(-50%)',
                backgroundColor: '#0f5673',
              }}
            />
          )}
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            disableRestoreFocus
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Box
              sx={{
                p: 2,
                maxWidth: 280,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <IconButton
                onClick={handleClosePopover}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'text.secondary',
                }}
              >
                <Close />
              </IconButton>
              <Avatar src={profilePic} alt="Avatar" sx={{ width: 200, height: 200 }} />
              <Typography
                sx={{
                  lineHeight: 1.2,
                  fontWeight: 'bold',
                }}
              >
                {fullname.toUpperCase()}
              </Typography>
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                {email}
              </Typography>
              <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
                {accType}
              </Typography>
            </Box>
          </Popover>

          <CoverImgStyle alt="cover" src={cover} />
        </CardMediaStyle>
      </Box>

      <Box onClick={() => handleScrollClick(fullname, _id)} sx={{ cursor: 'pointer' }}>
        <Typography variant="subtitle1" align="center" sx={{ mt: 6 }}>
          {fullname}
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: 'text.secondary' }}>
          {email}
        </Typography>
      </Box>

      {/* <Box sx={{ textAlign: 'center', mt: 2, mb: 2.5 }}>
        {SOCIALS.map((social) => (
          <Tooltip key={social.fullname} title={social.fullname}>
            <IconButton>{social.icon}</IconButton>
          </Tooltip>
        ))}
      </Box>

      <Divider /> */}

      {/* <Grid container sx={{ py: 3, textAlign: 'center' }}>
        {InfoItem(follower)}
        {InfoItem(following)}
        {InfoItem(totalPost)}
      </Grid> */}
    </Card>
  );
}
