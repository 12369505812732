import {
  Card,
  Container,
  Grid,
  Stack,
  Box,
  Button,
  Typography,
  CardContent,
  TablePagination,
  CircularProgress,
  AvatarGroup,
  Tooltip,
  Avatar,
  Zoom,
  Divider,
  IconButton,
  Badge,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { CalendarMonthOutlined } from '@mui/icons-material';

import Moment from 'moment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FlagCircleRoundedIcon from '@mui/icons-material/FlagCircleRounded';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useParams, Link, useSearchParams, useNavigate } from 'react-router-dom';
import Logo, { CardLogo } from '../components/Logo';
import Page from '../components/Page';

export const SubTaskView = () => {
  const [task, setTask] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 9;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  console.log('searchParams', searchParams);
  const category = searchParams.get('category');
  const id = searchParams.get('id');
  const [input, setInput] = useState(1);

  const getTask = (newPage) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/task/category/all`, {
        params: { projectId: id, category, page: newPage, size },
      })
      .then((res) => {
        console.log('task', res);
        setTask(res.data.data.data);
        setIsLoading(false);
        setTaskCount(res.data.data.maxRecord);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(taskCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    getTask(input - 1 >= 0 ? input - 1 : 0);
  };

  const handlePageChange = (event, newPage) => {
    setPages(newPage);
    setIsLoading(true);
    getTask(newPage);
  };
  useEffect(() => {
    getTask();
  }, []);

  const TaskColors = ['#F2E9DB', '#F2E9DB'];

  return (
    <Page>
      <Container>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {' '}
            <Card
              style={{
                backgroundColor: '#EBDECB',
                padding: '20px',
                // border: '5px solid',
                marginBottom: '5px',
              }}
            >
              <Link to={`/dashboard/project/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant="h4" textAlign={'center'}>
                  {task[0].projectData.name}
                </Typography>
              </Link>
            </Card>
            <Card>
              <Stack
                spacing={1}
                padding={1}
                direction={{ lg: 'row', md: 'row', sm: 'column-reverse', xs: 'column-reverse' }}
                marginTop={2}
                justifyContent={'space-between'}
              >
                <Box>
                  <Typography variant="h5" paddingX={1.5}>
                    {category}
                  </Typography>
                </Box>
                <Stack direction="row" justifyContent={'space-between'}>
                  <Button
                    startIcon={<AddCircleOutlineSharpIcon />}
                    variant="contained"
                    sx={{ height: '40px', marginRight: '5px', bgcolor: '#6b4f25', ':hover': { background: '#543d1b' } }}
                    onClick={() => navigate(`/dashboard/project/task/create?category=${category}&id=${id}`)}
                  >
                    Task
                  </Button>
                  <Tooltip TransitionComponent={Zoom} title="Close">
                    <IconButton onClick={() => navigate(-1)}>
                      <CloseRoundedIcon sx={{ color: 'black' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2} paddingX={2}>
                <TextField
                  size="small"
                  sx={{
                    maxWidth: '100px',
                    maxHeight: '40px',
                  }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button
                  variant="contained"
                  onClick={goToPage}
                  sx={{ maxHeight: '40px', bgcolor: '#6b4f25', ':hover': { background: '#543d1b' } }}
                >
                  Go
                </Button>
              </Stack>
              <Box
                sx={{
                  paddingX: '15px',
                  paddingY: '15px',
                  '@media screen and (max-width: 450px)': {
                    paddingX: '10px',
                  },
                }}
              >
                <Grid container spacing={{ xl: 1, lg: 1, md: 1, sm: 1, xs: 0 }} rowGap={1} xs={12}>
                  {task?.map((item, index) => (
                    <Grid key={index} item lg={4} md={6} sm={12} xs={12}>
                      <Link
                        to={`/dashboard/project/task/view?projectId=${id}&taskId=${item._id}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Card key={index} style={{ background: TaskColors[index % 1], height: '100%' }}>
                          <CardContent>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                  <CardLogo />
                                  <Typography
                                    variant="h5"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                  >
                                    {item.name.toUpperCase()}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Stack direction="row" sx={{ width: '100%' }} paddingX={1} mt={1} alignItems={'start'}>
                                <Stack direction="row" flex={1} alignItems={'start'} justifyContent="start">
                                  {item.startDate && (
                                    <>
                                      <Stack direction="row" alignItems={'start'}>
                                        <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                                          <CalendarMonthOutlined sx={{ color: 'green' }} />
                                        </Typography>
                                        {/* <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>Start Date</Typography> */}
                                      </Stack>
                                      <Typography style={{ fontSize: '15px' }}>
                                        {Moment(item.startDate).format('DD MMM YYYY')}
                                      </Typography>
                                    </>
                                  )}
                                </Stack>

                                <Stack direction="row" flex={1} alignItems={'start'}>
                                  {item.endDate && (
                                    <>
                                      <Stack direction="row" alignItems={'start'}>
                                        <Typography style={{ fontSize: '15px' }}>
                                          <CalendarMonthOutlined sx={{ color: 'red' }} />
                                        </Typography>
                                        {/* <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>End Date</Typography> */}
                                      </Stack>
                                      <Typography style={{ fontSize: '15px' }}>
                                        {Moment(item.endDate).format('DD MMM YYYY')}
                                      </Typography>
                                    </>
                                  )}
                                </Stack>
                              </Stack>
                              <Grid item xs={12} sm={6}>
                                <Stack direction="column">
                                  <Stack direction="row">
                                    <AttachFileIcon />
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold' }}>
                                      {item.attachmentsCount} Files
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </Grid>

                              <Grid item xs={12}>
                                <Stack direction="row">
                                  <AvatarGroup
                                    max={6}
                                    sx={{ display: 'flex', justifyContent: 'left', paddingTop: '10px' }}
                                  >
                                    {item?.members.map((mdata, id) => (
                                      <Tooltip key={id} TransitionComponent={Zoom} title={mdata.email}>
                                        <Badge
                                          invisible={!mdata.isBlocked}
                                          overlap="circular"
                                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                          badgeContent={
                                            <Icon fontSize="20px" icon="material-symbols:block" color="red" />
                                          }
                                        >
                                          {!mdata?.profilePic ? (
                                            <Avatar alt="" key={id} sx={{ bgcolor: '#3b93b8' }}>
                                              {mdata.fullname.charAt(0).toUpperCase()}
                                            </Avatar>
                                          ) : (
                                            <Avatar
                                              alt=""
                                              key={id}
                                              sx={{ bgcolor: '#3b93b8' }}
                                              src={mdata.profilePic}
                                            />
                                          )}
                                        </Badge>
                                      </Tooltip>
                                    ))}
                                  </AvatarGroup>
                                </Stack>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* <Box paddingBottom={5}>
              <Grid style={{ padding: '5px', minHeight: '300px' }} container spacing={1} columns={{ sm: 12 }}>
         
                                  </Stack>
                                </Grid>
                                <Grid item>
                                  <Stack>
                                    <Stack direction="row">
                                      <PendingActionsIcon />
                                      <Typography style={{ fontSize: '16px', paddingLeft: '5px' }}>Status</Typography>
                                    </Stack>
                                    <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '5px' }}>
                                      {item.status.split('_').join(' ')}
                                    </Typography>
                                  </Stack>
                                </Grid>
                              </Stack>
                              
                              <Divider sx={{ paddingTop: '20px' }} />
                              <Typography textAlign="center" paddingTop="20px" variant="h6">
                                View More
                              </Typography>
                            </CardContent>
                          </Grid>
                        </Card>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Box> */}
            </Card>
          </>
        )}
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={taskCount}
          page={pages}
          onPageChange={handlePageChange}
          rowsPerPage={size}
          showFirstButton
          showLastButton
        />
      </Container>
    </Page>
  );
};
