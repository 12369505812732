import { CalendarMonthOutlined, Category, Description, LocationOn } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Card,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';

export default function ProjectDescriptionCard({ project, id, getProjectById, category }) {
  const [description, setDescription] = React.useState(project?.description);
  const [location, setLocation] = React.useState(project?.location);
  const [selectedCategory, setSelectedCategory] = React.useState(project?.category);
  const handleUpdateStartDate = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        startDate: e.target.value,
      });
      toast.success('Start date updated');
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const handleUpdateEndDate = async (e) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        endDate: e.target.value,
      });
      toast.success('End date updated');
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const handleUpdateLocation = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        location,
      });
      toast.success('Location updated');
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };

  const handleUpdateCategory = async (e) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        category: e.target.value,
      });
      toast.success('Category updated');
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
    }
  };
  const updateDescription = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/project/edit`, {
        projectId: id,
        description,
      });
      toast.success('Project Description Successfully Updated');
      getProjectById();
    } catch (error) {
      console.log(error);
      toast.error(error.resposnse.data.message);
    }
  };
  return (
    <Grid item lg={8} md={7} sm={12} spacing={1} xs={12}>
      <Card
        sx={{
          bgcolor: '#ede8e8',
          minHeight: '420px',
          marginRight: { lg: '10px', xl: '10px', md: '5px', sm: '0px', xs: '0px' },
          marginLeft: { lg: '5px', xl: '5px', md: '2px', sm: '0px', xs: '0px' },
          marginBottom: '10px',
        }}
      >
        <CardContent>
          <Grid container sx={12} spacing={1} direction="row" justifyContent="space-between">
            <Grid md={6} sm={6} xs={12} item marginBottom="10px">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                  <CalendarMonthOutlined sx={{ color: 'green' }} />
                </Typography>
                <TextField
                  sx={{ width: '145px' }}
                  type="date"
                  onMonthChange={() => {}}
                  onYearChange={() => {}}
                  size="small"
                  onChange={handleUpdateStartDate}
                  InputLabelProps={{ shrink: true }}
                  value={moment(project?.startDate).format('YYYY-MM-DD')}
                />
              </Stack>
            </Grid>

            <Grid md={6} sm={6} xs={12} item marginBottom="10px">
              <Stack
                direction="column"
                alignItems={{ lg: 'flex-end', md: 'flex-end', sm: 'flex-start', xs: 'flex-start' }}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    <CalendarMonthOutlined sx={{ color: 'red' }} />
                  </Typography>
                  <TextField
                    sx={{ width: '145px' }}
                    type="date"
                    size="small"
                    onChange={handleUpdateEndDate}
                    InputLabelProps={{ shrink: true }}
                    value={moment(project?.endDate).format('YYYY-MM-DD')}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid md={12} sm={12} xs={12} item marginBottom="10px">
              <Stack>
                <Stack direction={'row'}>
                  <Category />
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      paddingLeft: '3px',
                    }}
                  >
                    Category
                  </Typography>
                </Stack>
                <FormControl fullWidth>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      handleUpdateCategory(e);
                    }}
                  >
                    <MenuItem value="EDUCATIONAL">Educational</MenuItem>
                    <MenuItem value="COMMERCIAL_INTERIOR">Commercial Interior</MenuItem>
                    <MenuItem value="CULTURAL">Cultural</MenuItem>
                    <MenuItem value="HOSPITALITY">Hospitality</MenuItem>
                    <MenuItem value="COMMERCIAL">Commercial</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Grid>

            <Grid md={12} sm={12} xs={12} item marginBottom="10px">
              <Stack>
                <Stack direction={'row'}>
                  <LocationOn />
                  <Typography
                    style={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      paddingLeft: '3px',
                    }}
                  >
                    Location
                  </Typography>
                </Stack>
                <Stack direction={{ md: 'row', sm: 'column', xs: 'column' }} alignItems="center" spacing={1}>
                  <TextField
                    fullWidth
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                  <LoadingButton
                    sx={{
                      height: '40px',
                      width: { xs: '100%', sm: '80px' },
                      bgcolor: '#575151',
                      ':hover': { background: '#423e3e' },
                    }}
                    onClick={handleUpdateLocation}
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>

            <Grid md={12} sm={12} xs={12} item marginBottom="5px">
              <Stack direction="column" spacing={0.5}>
                <Stack direction="row">
                  <Description />
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold', paddingLeft: '3px' }}>
                    Description
                  </Typography>
                </Stack>
                <Stack direction={{ md: 'row', sm: 'column', xs: 'column' }} alignItems="center" spacing={1}>
                  <TextField
                    multiline
                    rows={5}
                    maxRows={10}
                    size="small"
                    fullWidth
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <LoadingButton
                    sx={{
                      height: '40px',
                      width: { xs: '100%', sm: '80px' },
                      bgcolor: '#575151',
                      ':hover': { background: '#423e3e' }, 
                    }}
                    onClick={updateDescription}
                    variant="contained"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
