import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroup,
  FormLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem,
  TextareaAutosize,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function AddUserForm({ closePopup }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    fullname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Full name required'),
    accType: Yup.string().required('Role required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    gender: Yup.string().oneOf(['MALE', 'FEMALE', 'OTHER'], 'Required').required('Required'),
    mobile: Yup.string().required('Mobile is required'),
    address: Yup.string().required('Address is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      accType: '',
      email: '',
      gender: '',
      mobile: '',
      password: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      console.log('clicked');
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/register`, values,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        console.log(response);
        if (!response.data.isError) {
          // navigate('/dashboard/member', { replace: true });
          toast.success(response.data.message);
          closePopup();
        } else {
          console.log(response.data);
          toast.error(response.data.message);
        }
        // if (response) {
        //   if (response.data.isError) {
        //     if (response.data.message === "session exists") {
        //       navigate("/error");
        //     } else {
        //       setError(response.data.message);
        //     }
        //   } else {
        //     console.log(response);
        //     setData(response.data);
        //     setVerify(true);
        //   }
        // }
      } catch (error) {
        console.log(error);
        return false;
      }

      //   navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, handleSubmit, values, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Full name"
              {...getFieldProps('fullname')}
              error={Boolean(touched.fullname && errors.fullname)}
              helperText={touched.fullname && errors.fullname}
            />

            {/* <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            /> */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="accType"
                {...getFieldProps('accType')}
                label="Role"
                onChange={formik.handleChange}
              >
                <MenuItem value="ARCHITECT">ARCHITECT</MenuItem>
                <MenuItem value="CLIENT">CLIENT</MenuItem>
                <MenuItem value="CONTRACTOR">CONTRACTOR</MenuItem>
                <MenuItem value="EMPLOYEE">EMPLOYEE</MenuItem>
                <MenuItem value="PARTNER">PARTNER</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Grid item xs={12}>
            <FormControl {...getFieldProps('gender')}>
              <FormLabel id="demo-row-radio-buttons-group-label" name="gender">
                Gender
              </FormLabel>
              <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="gender">
                <FormControlLabel value="FEMALE" control={<Radio />} label="Female" />
                <FormControlLabel value="MALE" control={<Radio />} label="Male" />
                <FormControlLabel value="OTHER" control={<Radio />} label="Other" />
              </RadioGroup>
            </FormControl>
            {/* <FormHelperText error={props.errors.gender && props.touched.gender}>
              <ErrorMessage name="gender" />
            </FormHelperText> */}
          </Grid>
          <TextField
            fullWidth
            label="mobile"
            {...getFieldProps('mobile')}
            error={Boolean(touched.mobile && errors.mobile)}
            helperText={touched.mobile && errors.mobile}
          />
          <TextField
            fullWidth
            label="Address"
            {...getFieldProps('address')}
            error={Boolean(touched.address && errors.address)}
            helperText={touched.address && errors.address}
          />
          {/* <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Minimum 3 rows"
            style={{ width: 200 }}
          /> */}

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            type="file"
            InputLabelProps={{
              shrink: true,
            }}
            label="Upload Profile Picture"
            onChange={(event) => {
              formik.setFieldValue('profilePic', event.currentTarget.files[0]);
            }}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
