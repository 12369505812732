import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, FormControl, Select, MenuItem } from '@mui/material';
import Iconify from './Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState([null, null]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                name: '',
                accType: 'PARTNER',
              }}
              onSubmit={async (values) => {
                applyFilters(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" sx={{ minWidth: '250px' }}>
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="name"
                      name="name"
                      label="Member name"
                      value={values.name}
                      onChange={handleChange}
                    />

                    <Stack direction="row" marginBottom="10px" spacing={1}>
                      <TextField
                        select
                        fullWidth
                        id="accType"
                        name="accType"
                        label="Role"
                        value={values.accType}
                        onChange={handleChange}
                      >
                        <MenuItem value="EMPLOYEE">Employee</MenuItem>
                        <MenuItem value="CLIENT">Client</MenuItem>
                        <MenuItem value="CONTRACTOR">Contractor</MenuItem>
                        <MenuItem value="PARTNER">Partner</MenuItem>
                        <MenuItem value="ARCHITECT">Architect</MenuItem>
                      </TextField>
                    </Stack>

                    <Button variant="contained" sx={{ bgcolor: '#0F5673' }} type="submit">
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
